import React from "react";
import loadami from "../assets/logo/loading.gif";

function Loader() {
  return (
    <>
      <div className="">
        <div className="flex justify-center items-center h-[100vh]">
        <img className="2xl:h-[150px] xl:h-[150px] lg:h-[150px] md:h-[150px] sm:h-[130px] h-[100px]" src={loadami} alt="" />
        </div>
      </div>
    </>
  );
}

export default Loader;
